import clsx from 'clsx'
import { type ReactNode } from 'react'

export type PageContentProps = {
	className?: string
	children: ReactNode | ReactNode[]
}

export function Content({ className, children }: PageContentProps) {
	return (
		<main className={clsx("relative", className)}>
			{children}
		</main>
	)
}
